<template>
  <div>
    <fieldsTds
      :fieldAttrRow="fieldAttrRow"
      :fieldAttributes="{
        ...fieldAttributes,
        options: options,
        type: 'combobox',
      }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :value="valueLine[field]"
      :valueLine="valueLine"
    ></fieldsTds>
  </div>
</template>
<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";
export default {
  components: { fieldsTds },
  props: [
    "field",
    "fieldAttributes",
    "value",
    "valueLine",
    "isValid",
    "result",
    "fieldAttrRow",
  ],
  created() {
    this.$nextTick(function () {
      this.$emit("revalidate");
    });
  },
  computed: {
    options() {
      let options = [];
      if (
        typeof this.fieldAttributes.info != "undefined" &&
        this.fieldAttributes.info != null
      ) {
        options = Object.keys(this.fieldAttributes.info);
      }
      return options;
    },
  },
};
</script>